import { message } from 'antd'
import { useQuery } from 'react-query'
import { useQueryString } from '../hooks/useQueryString'
import $api from '../http/$api'
import { apis, SEARCH_BY_PASSPORT_CLIENT } from '../http/apis'
import { queryNames } from './queryNames'
import { stringify } from 'query-string'
import axios from 'axios'
import { contractStatus } from '../utils/constants/contractStatus'
import { useDispatch, useSelector } from 'react-redux'
import { setProjectVersion } from '../redux/actions'

export function useOneCompany(id, url = apis.BUILDING_COMPANY) {
  return useQuery(
    [url, id || 'company'],
    async () => {
      const res = await $api.get(id ? url + '/' + id : url)
      return res?.data?.data
    },
    { enabled: id === 0 || !!id }
  )
}

export function useSearchContract(searchString) {
  return useQuery(
    [queryNames.CONTRACTS_SEARCH, searchString],
    async () => {
      const res = await $api.get(`${apis.CONTRACTS}?name=${searchString}`)

      return res?.data?.data
    },
    {
      enabled: !!searchString,
    }
  )
}

export function useContracts({ searchFields, page, archieve }) {
  const { ACTIVE, BLANK, CANCELLED, FINISHED, STARTED } = contractStatus

  // status
  const status = () => {
    let arr = []

    if (searchFields.statuses?.length > 0) {
      return ''
    }

    if (archieve) {
      arr = [CANCELLED, FINISHED, BLANK]
    } else {
      arr = [ACTIVE, STARTED]
    }

    const stringified = stringify(
      { statuses: arr },
      {
        arrayFormat: 'comma',
      }
    )

    return `${stringified}&`
  }

  return useQuery(
    [
      queryNames.CONTRACTS,
      page,
      searchFields.start,
      searchFields.finish,
      searchFields.statuses,
      searchFields.types,
      searchFields.name,
      searchFields?.brokerId,
      archieve,
      status(),
    ],
    async () => {
      const string = stringify(searchFields, {
        arrayFormat: 'comma',
        skipNull: true,
      })

      const promise = await $api.get(
        apis.CONTRACTS_ALL + `?page=${page}&size=10&${status()}` + string
      )

      return promise.data.data
    },
    {
      enabled: !!searchFields,
    }
  )
}

export function useOneContract(id) {
  return useQuery(
    [queryNames.CONTRACTS, id],
    async () => {
      const res = await $api.get(`${apis.CONTRACTS}/${id}`)
      return res?.data?.data
    },
    {
      enabled: id !== 0,
    }
  )
}

export function useOneContractInfo(id) {
  return useQuery(
    [queryNames.CONTRACTS, queryNames.INFO, id],
    async () => {
      const resCont = await $api.get(`${apis.CONTRACTS}/${id}`)
      const resPayments = await $api.get(
        `${apis.CONTRACTS}/${id}${apis.PAYMENTS}`
      )
      const resMonthlyPayments = await $api.get(
        `${apis.CONTRACTS}/${id}${apis.MONTHLY_PAYMENTS}`
      )
      const resProducts = await $api.get(
        `${apis.CONTRACTS}/${id}${apis.PRODUCT}`
      )
      return {
        contract: resCont?.data?.data,
        tableMonthlyPayments: resMonthlyPayments?.data?.data,
        historyPayments: resPayments?.data?.data,
        products: resProducts?.data?.data,
      }
    },
    {
      enabled: id !== 0,
      onError: (err) => {
        message.error(err.response?.data?.message)
        console.log(err.response?.data?.message)
      },
    }
  )
}

export function usePaymentPlusTables(id) {
  return useQuery(
    [queryNames.PAYMENT_PLUS_TABLES, id],
    async () => {
      const resPayments = await $api.get(
        `${apis.CONTRACTS}/${id}${apis.PAYMENTS}`
      )
      const resMonthlyPayments = await $api.get(
        `${apis.CONTRACTS}/${id}${apis.MONTHLY_PAYMENTS}`
      )
      return {
        tableMonthlyPayments: resMonthlyPayments?.data?.data,
        historyPayments: resPayments?.data?.data,
      }
    },
    {
      enabled: id !== 0,
    }
  )
}
// TODO : do pagination
export function useBuilderCompany(url) {
  const { query } = useQueryString()
  return useQuery([url, 'page', query.page], async () => {
    const res = await $api.get(url + '?page=' + (query.page || 1) + '&size=6')
    return res?.data?.data
  })
}

export function usePayments(strings) {
  return useQuery([queryNames.PAYMENTS, strings?.filter], async () => {
    const res = await $api.get(apis.PAYMENTS_ALL + strings?.filter)
    // const resSum = await $api.get(apis.PAYMENTS_SUM + strings?.sum)
    // const resToday = await $api.get(apis.PAYMENTS_SUM_TODAY)
    return {
      table: res?.data?.data,
      // sum: resSum?.data?.data,
      // sumToday: resToday?.data?.data
    }
  })
}

export function useArrearage(searchString, sort) {
  return useQuery([queryNames.ARREARAGE, searchString, sort], async () => {
    const res = await $api.post(apis.ARREARAGE_LIST + searchString, {
      [sort.by]: sort.type,
    })
    return res?.data?.data
  })
}

export function useMessage(searchString) {
  return useQuery([queryNames.MESSAGE, searchString], async () => {
    const res = await $api.get(apis.MESSAGES_ALL + searchString)
    return res?.data?.data
  })
}

export function useMessageText(value) {
  return useQuery(
    [queryNames.MESSAGE_TEXT, value],
    async () => {
      const res = await $api.get(`${apis.MESSAGE_TEMPLATE}/type/${value}`)
      return res?.data?.data
    },
    {
      enabled: !!value,
    }
  )
}

export function useEmployeeSelects(isCompany) {
  return useQuery(
    [queryNames.EMPLOYEE_SELECTS],
    async () => {
      const urls = isCompany ? [apis.ROLES] : [apis.ROLES, apis.COMPANY_SELECT]
      const res = await Promise.all(urls.map((url) => $api.get(url)))
      return {
        roles: res[0]?.data?.data?.filter((item) => item.name !== 'DEVELOPER'),
        companies: res[1]?.data?.data || [],
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  )
}
export function useSmsSettings() {
  return useQuery(
    [queryNames.SMS_SETTINGS],
    async () => {
      const res = await $api.get(apis.BUILDING_COMPANY + apis.CONFIG)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
    }
  )
}

export function useProductSearch(productName) {
  return useQuery(
    [queryNames.PRODUCT_SEARCH, productName],
    async () => {
      if (productName.length > 2) {
        const res = await $api.get(apis.PRODUCT_SEARCH + productName)
        return res.data.data
      }
    },
    {
      enabled: !!productName,
    }
  )
}

// clients
export function useClients(page, text) {
  let url = `${apis.CLIENT}?page=${page}`

  if (text) {
    url += `&name=${text}`
  }

  return useQuery(
    [queryNames.CLIENTS, page, text],
    async () => {
      const res = await $api.get(url)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
    }
  )
}

// detailed client
export function useDetailedClient(id) {
  return useQuery(
    [queryNames.DETAILED_CLIENT, id],
    async () => {
      const res = await $api.get(`${apis.CLIENT}/${id}`)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  )
}

// contracts of client
export function useContractsOfClient(id) {
  return useQuery(
    [queryNames.CONTRACTS_OF_CLIENT],
    async () => {
      const res = await $api.get(`${apis.CONTRACT + apis.CLIENT}/${id}`)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
    }
  )
}

// parts of contract
export function usePartsOfContract(contractId) {
  return useQuery(
    [queryNames.PARTS_OF_CONTRACT, contractId],
    async () => {
      const res = await $api.get(`${apis.CONTRACT}/${contractId}${apis.PARTS}/`)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
    }
  )
}

// search client by passport information
export function useSearchClientBuPassportData(
  passportSerialAndNumber,
  dateBirth
) {
  return useQuery(
    [
      queryNames.FIND_CLIENT_SEARCH_BY_PASSPORT_DATA,
      passportSerialAndNumber,
      dateBirth,
    ],
    async () => {
      const convertedDateBirth = dateBirth.split('.').reverse().join('-')
      const res = await axios.get(
        SEARCH_BY_PASSPORT_CLIENT +
          `?series=${passportSerialAndNumber.slice(
            0,
            2
          )}&number=${passportSerialAndNumber.slice(
            2,
            9
          )}&birth_date=${convertedDateBirth}`
      )
      return res.data.data.passport
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!passportSerialAndNumber && !!dateBirth,
      retry: false,
      onError: () => {
        message.error('Mijoz topilmadi!')
      },
    }
  )
}

export function useCheckNewVersion() {
  const projectVersion = useSelector((state) => state.projectVersion.version)
  const dispatch = useDispatch()

  return useQuery([queryNames.CHECK_NEW_VERSION], async () => {
    const res = await axios.get('/version.json')
    if (!projectVersion) {
      dispatch(setProjectVersion(res.data.version))
    }
    return res.data.version
  })
}

export function useGetCurrency() {
  return useQuery([queryNames.CURRENCY_CONFIG], async () => {
    const res = await $api.get(`${apis.CURRENCY}`)
    return res.data.data
  })
}

export function useSmsSystem() {
  return useQuery([queryNames.SMS_SYSTEM], async () => {
    const res = await $api.get(`${apis.SMS}/system`)
    return res.data.data
  })
}
